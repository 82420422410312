import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Box, Button, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { ApiDataListType, ApiDataResponseListLengthCheck, GetOrgId, PAGE_SIZE } from "../constants/options";
import { t } from "i18next";
import { encryptedPassword } from "../constants/settings";
import ContentLayout from "../components/shared-content-layout";
import VmTable from "../components/shared-table";
import { TD_FIRST, TD_NORMAL, TD_LAST } from "../constants/style";
import VmModal from "../components/shared-modal";
import useStores from "../hooks/use-stores";



const UserPage = observer(() => {
  const { rootStore, userStore } = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  // Determine is update or create
  const [targetUser, setTargetUser] = useState<any>();
  const [title, setTitle] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [middleName, setMiddleName] = useState<string>("");
  const [birthDate, setBirthDate] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [oldPassword, setOldPassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [cPassword, setCPassword] = useState<string>("");
  const [roldId, setRoldId] = useState<string>("0");
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (ApiDataResponseListLengthCheck(ApiDataListType.USER) == 0
      || userStore.userProfileList.data.length > PAGE_SIZE) {
      userStore.getOrganisationUserProfileList(PAGE_SIZE, 0);
    }
  }, []);


  const onActionAdmin = () => {
    if (!targetUser && password !== cPassword) {
      rootStore.notify(t('TWO_PASSWORDS_NOT_MATCH'), 'warning');
      return;
    };
    let req: any = {
      title,
      firstName,
      lastName,
      middleName,
      birthDate,
      emailAddress,
      username,
      organisationId: GetOrgId(),
      password: password === encryptedPassword ? oldPassword : password,
    }
    if (targetUser) {
      req.id = targetUser.id;
      userStore.updateOrganisationUserProfile(req).then(() => {
        rootStore.notify('User Profile' + t('UPDATED_B'), 'success');
        userStore.getOrganisationUserProfileList(PAGE_SIZE, (page - 1) * PAGE_SIZE);
      }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'error'));
    } else {
      userStore.updateOrganisationUserProfile(req).then(() => {
        rootStore.notify('User Profile' + t('CREATED_B'), 'success')
        userStore.getOrganisationUserProfileList(PAGE_SIZE, 0);
      }).catch(() => rootStore.notify(t('CREATE_FAILED'), 'error'));
    }
    handleCloseModal();
  }

  const handleShowModal = (id?: number) => {
    if (id) {
      let target = userStore.userProfileList.data.find(u => u.id == id);
      setTargetUser(target);
      setTitle(target.title);
      setFirstName(target.firstName);
      setLastName(target.lastName);
      setMiddleName(target.middleName);
      setBirthDate(target.birthDate.split('T')[0]);
      setEmailAddress(target.emailAddress);
      setUsername(target.username);
      setOldPassword(target.password);
      setPassword(encryptedPassword);
      setRoldId(target.role);
    }
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setShowDeleteModal(false);
    setTargetUser(null);
    setTitle("");
    setFirstName("");
    setLastName("");
    setMiddleName("");
    setBirthDate("");
    setEmailAddress("");
    setUsername("");
    setCPassword("");
    setOldPassword("");
    setPassword("");
    setRoldId("0");
  }

  const handleDeleteModal = (id: number) => {
    let target = userStore.userProfileList.data.find(u => u.id == id);
    setTargetUser(target);
    setShowDeleteModal(true);
  }

  return (
    <ContentLayout
      pageName="User"
      pageHeading="User"
      buttonLabel={t('ADD_A') + 'User'}
      onClickAction={() => handleShowModal()}
    >
      <VmTable
        loading={userStore.loading}
        page={page}
        paginationCount={userStore.userProfileList.totalCount == 0 ? 1 : userStore.userProfileList.totalCount / PAGE_SIZE}
        onChangePagination={(event, pageNumber) => {
          setPage(pageNumber);
          userStore.getOrganisationUserProfileList(PAGE_SIZE, (pageNumber - 1) * PAGE_SIZE);
        }}
        thead={["ID", t('NAME'), t('USERNAME'), t('BOD'), t('EMAIL'), t('ACTION')]}
        tbody={userStore.userProfileList.data.length > 0 &&
          userStore.userProfileList.data.map((user: any, index: number) => (
            <>
              <Box sx={{ marginY: 1 }} />
              <tr key={`user_${index}`}>
                <td className={TD_FIRST}>{index + 1}</td>
                <td className={TD_NORMAL}>{user.firstName + " " + user.lastName}</td>
                <td className={TD_NORMAL}>{user.username}</td>
                <td className={TD_NORMAL}>{user.birthDate.split('T')[0]}</td>
                <td className={TD_NORMAL}>{user.emailAddress}</td>
                <td className={TD_LAST}>
                  <Button
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                    onClick={() => handleShowModal(user.id)}
                  >
                    {t('EDIT')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteModal(user.id)}
                  >
                    {t('DELETE')}
                  </Button>
                </td>
              </tr>
              <Box sx={{ marginY: 1 }} />
            </>
          ))}
      />

      <VmModal
        openModal={showModal}
        onClose={handleCloseModal}
        width={500}
        title={targetUser ? t('UPDATE_A') + t('User Profile') : t('ADD_A') + t('User Profile')}
        buttonLabel={targetUser ? t('CONFIRM_A') + t('UPDATE') : t('CONFIRM_A') + t('CREATION')}
        buttonLabelWithoutConfirm={targetUser ? t('UPDATE_NOW') : t('CREATE_NOW')}
        onClickConfirmedButton={onActionAdmin}
        disabledCondition={title === "" || firstName === "" || lastName === "" || username === ""
          || password === "" || (!targetUser && cPassword === "") || emailAddress === ""
        }
      >
        <div className="grid grid-cols-2 gap-4">
          <TextField
            value={title}
            label={t('TITLE') + ' *'}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setTitle(value.target.value)}
          />
          <TextField
            value={username}
            label={t('USERNAME') + ' *'}
            variant="outlined"
            disabled={targetUser}
            sx={{ width: '100%' }}
            onChange={(value) => setUsername(value.target.value)}
          />
          <TextField
            value={firstName}
            label={t('FIRST_NAME') + ' *'}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setFirstName(value.target.value)}
          />
          <TextField
            value={lastName}
            label={t('LAST_NAME') + ' *'}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setLastName(value.target.value)}
          />
          <TextField
            value={middleName}
            label={t('MIDDLE_NAME')}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setMiddleName(value.target.value)}
          />
          <TextField
            value={birthDate}
            label={t('BOD') + ' *'}
            type="date"
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setBirthDate(value.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            value={password}
            label={t('PASSWORD') + ' *'}
            type="password"
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setPassword(value.target.value)}
          />
          <TextField
            value={cPassword}
            type="password"
            label={t('CONFIRM_PASSWORD') + ' *'}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setCPassword(value.target.value)}
          />
          <TextField
            value={emailAddress}
            label={t('EMAIL') + ' *'}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setEmailAddress(value.target.value)}
          />
          {/* <FormControl fullWidth>
            <InputLabel>{t('USER_ROLE')}</InputLabel>
            {
              permissionStore.roleDetailList.length > 0 &&
              <Select
                label={t('USER_ROLE')}
                sx={{ width: '100%', marginBottom: 2 }}
                value={roldId}
                onChange={onChangeRole}
              >
                <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
                {permissionStore.roleDetailList.map((role: any, index: number) => (
                  <MenuItem key={`role_select_${index}`} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            }
          </FormControl> */}
        </div>
      </VmModal>

      <VmModal
        openModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={t('DELETE_A') + t('CONFIRMATION')}
        buttonLabel={t('CONFIRM_DELETE')}
        buttonLabelWithoutConfirm={t('DELETE_NOW')}
        onClickConfirmedButton={() => userStore.deleteOrganisationUserProfile(targetUser.id).then(() => {
          rootStore.notify(t('User Profile') + t('DELETED_B'), 'success')
          userStore.getOrganisationUserProfileList(PAGE_SIZE, 0);
          handleCloseModal();
        }).catch(() => rootStore.notify(t('DELETE_FAILED'), 'error'))}
      >
        <Typography sx={{ marginBottom: 2 }}>{t('DELETE_CHECK')}?</Typography>
        <Typography color="red" variant="caption">
          All relevant data will also be deleted
        </Typography>
      </VmModal>
    </ContentLayout>
  )
});

export default UserPage;
